var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var BH,CH,EH,FH,Jfa,Kfa,Lfa,GH,DH;$CLJS.zH=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.be.g($CLJS.J.j(c,e,$CLJS.Cf),d))},$CLJS.ec($CLJS.N),b))};$CLJS.AH=function(a,b){return $CLJS.fb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
BH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.wC(b,$CLJS.XC)?$CLJS.kH:$CLJS.wC(b,$CLJS.WD)?$CLJS.kE:$CLJS.wC(b,$CLJS.wD)?$CLJS.lE:null;return $CLJS.n(b)?$CLJS.vF(b,a):!0};
CH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.zH(function(d){return $CLJS.wC($CLJS.TE(d),$CLJS.hD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Nk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.TE($CLJS.A(a));return $CLJS.Re(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(BH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
EH=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.wt,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.DE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Cj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(CH(b))].join("")}],null),$CLJS.Te(CH)],null)],null)};
FH=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ls,new $CLJS.h(null,1,[$CLJS.wt,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.DE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null)],null)],null)};
Jfa=function(a){return $CLJS.Qd($CLJS.yF,$CLJS.hf.g(function(b){var c=$CLJS.TE(b),d=$CLJS.wC(c,$CLJS.eF);b=d?$CLJS.vF($CLJS.sG,b):d;return $CLJS.n(b)?$CLJS.Oj:c},a))};Kfa=function(a){a=$CLJS.AH(function(b){return!$CLJS.wC(b,$CLJS.hD)},$CLJS.hf.g($CLJS.TE,a));return $CLJS.wC(a,$CLJS.eF)?$CLJS.Vj:a};
Lfa=function(a,b){return $CLJS.n($CLJS.Re(function(c){return $CLJS.wC($CLJS.TE(c),$CLJS.hD)},b))?Kfa(b):$CLJS.E.g(a,$CLJS.pt)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Qe(function(c){return $CLJS.wC($CLJS.TE(c),$CLJS.XC)},b)||$CLJS.Qe(function(c){return $CLJS.wC($CLJS.TE(c),$CLJS.wD)},b))?$CLJS.hD:Jfa(b)};GH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.HH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);DH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,DH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.wt,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.TE(a);return $CLJS.Qe(function(d){return BH(d,c)},b)}],null)],null));
$CLJS.X(GH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null)],null));
var Mfa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ls,new $CLJS.h(null,1,[$CLJS.wt,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.pt],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.DE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null)],
null);$CLJS.PG.g($CLJS.bs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,EH($CLJS.bs),FH($CLJS.bs)],null));$CLJS.PG.g($CLJS.pt,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,EH($CLJS.pt),Mfa,FH($CLJS.pt)],null));$CLJS.FF($CLJS.cs,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,GH],null)]));$CLJS.FF($CLJS.KG,$CLJS.H([$CLJS.pt,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,GH],null)]));
for(var IH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bs,$CLJS.pt,$CLJS.cs],null)),JH=null,KH=0,LH=0;;)if(LH<KH){var Nfa=JH.X(null,LH);$CLJS.wF(Nfa,$CLJS.HH);LH+=1}else{var MH=$CLJS.y(IH);if(MH){var NH=MH;if($CLJS.Ad(NH)){var OH=$CLJS.lc(NH),Ofa=$CLJS.mc(NH),Pfa=OH,Qfa=$CLJS.D(OH);IH=Ofa;JH=Pfa;KH=Qfa}else{var Rfa=$CLJS.A(NH);$CLJS.wF(Rfa,$CLJS.HH);IH=$CLJS.B(NH);JH=null;KH=0}LH=0}else break}
$CLJS.SE.m(null,$CLJS.HH,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);$CLJS.A(b);b=$CLJS.B(b);return Lfa(a,b)});$CLJS.DF($CLJS.PF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null)]));$CLJS.wF($CLJS.PF,$CLJS.iF);
for(var PH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MG,$CLJS.GF,$CLJS.bG],null)),QH=null,RH=0,SH=0;;)if(SH<RH){var Sfa=QH.X(null,SH);$CLJS.DF(Sfa,$CLJS.H([$CLJS.pt,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null)]));SH+=1}else{var TH=$CLJS.y(PH);if(TH){var UH=TH;if($CLJS.Ad(UH)){var VH=$CLJS.lc(UH),Tfa=$CLJS.mc(UH),Ufa=VH,Vfa=$CLJS.D(VH);PH=Tfa;QH=Ufa;RH=Vfa}else{var Wfa=$CLJS.A(UH);$CLJS.DF(Wfa,$CLJS.H([$CLJS.pt,$CLJS.TD,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null)]));PH=$CLJS.B(UH);QH=null;RH=0}SH=0}else break}
for(var WH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KF,$CLJS.mG,$CLJS.tG],null)),XH=null,YH=0,ZH=0;;)if(ZH<YH){var Xfa=XH.X(null,ZH);$CLJS.DF(Xfa,$CLJS.H([$CLJS.pt,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null)]));ZH+=1}else{var $H=$CLJS.y(WH);if($H){var aI=$H;if($CLJS.Ad(aI)){var bI=$CLJS.lc(aI),Yfa=$CLJS.mc(aI),Zfa=bI,$fa=$CLJS.D(bI);WH=Yfa;XH=Zfa;YH=$fa}else{var aga=$CLJS.A(aI);$CLJS.DF(aga,$CLJS.H([$CLJS.pt,$CLJS.vj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null)]));WH=$CLJS.B(aI);XH=null;YH=0}ZH=0}else break}$CLJS.DF($CLJS.MF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null)]));
$CLJS.SE.m(null,$CLJS.MF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.wC($CLJS.TE(b),$CLJS.vj)&&$CLJS.wC($CLJS.TE(a),$CLJS.vj)?$CLJS.vj:$CLJS.TD});