var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.equality.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';var Uqa,Vqa;
Uqa=function(a,b){return function f(d,e){return new $CLJS.ne(null,function(){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){for(var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m),u=0;;)if(u<m){var v=function(){var x=d+u,z=$CLJS.kd(l,u);return a.g?a.g(x,z):a.call(null,x,z)}();null!=v&&t.add(v);u+=1}else break;return $CLJS.te($CLJS.ve(t),f(d+m,$CLJS.mc(k)))}m=function(){var x=$CLJS.A(k);return a.g?a.g(d,x):a.call(null,d,x)}();return null==m?f(d+1,$CLJS.Lc(k)):$CLJS.ee(m,f(d+1,$CLJS.Lc(k)))}return null},null,null)}(0,
b)};$CLJS.U5=function(a,b,c){return $CLJS.i2($CLJS.RF,new $CLJS.P(null,3,5,$CLJS.Q,[a,b,c],null))};Vqa=new $CLJS.M(null,"same-temporal-bucket?","same-temporal-bucket?",2110106995);$CLJS.L0.m(null,$CLJS.cG,function(a,b){var c=$CLJS.Fe($CLJS.cG.h($CLJS.MW(a,b)));return $CLJS.n(c)?$CLJS.oE("Grouped by {0}",$CLJS.H([$CLJS.Ht([" ",$CLJS.p.h($CLJS.QE("and"))," "].join(""),function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.i0.v(a,b,v,$CLJS.j0);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}t=$CLJS.A(k);return $CLJS.ee($CLJS.i0.v(a,b,t,$CLJS.j0),f($CLJS.Lc(k)))}return null}},null,null)}(c)}())])):null});$CLJS.V5=function(){function a(d,e,f){f=$CLJS.xW($CLJS.md(f)?f.g?f.g(d,e):f.call(null,d,e):f);return $CLJS.l2(d,e,$CLJS.cG,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.W5=function(){function a(d,e){return $CLJS.Fe($CLJS.cG.h($CLJS.MW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.X5=function(){function a(d,e){var f=$CLJS.W5.g(d,e);return null==f?null:$CLJS.Rk.g(function(k){return $CLJS.R.j($CLJS.h0.j(d,e,k),$CLJS.UK,$CLJS.gL)},f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Y5=function(){function a(d,e){var f=function(){var l=$CLJS.MW(d,e);return $CLJS.T0.v(d,e,l,new $CLJS.h(null,1,[$CLJS.F0,!1],null))}();if($CLJS.y(f)){var k=$CLJS.eg.g($CLJS.N,Uqa(function(l,m){m=$CLJS.u2.N(d,e,m,f,new $CLJS.h(null,1,[$CLJS.r2,!0],null));return $CLJS.n(m)?new $CLJS.P(null,2,5,$CLJS.Q,[m,l],null):null},function(){var l=$CLJS.W5.g(d,e);return $CLJS.n(l)?l:$CLJS.Cf}()));return $CLJS.Rk.g(function(l){var m=k.h?k.h(l):k.call(null,l);return $CLJS.n(m)?$CLJS.R.j(l,$CLJS.r0,m):l},f)}return null}
function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Z5=function(){function a(d,e,f,k){k=$CLJS.O(k);var l=$CLJS.J.g(k,Vqa);return $CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.kf(function(m){var t=$CLJS.u2.N(d,e,m,new $CLJS.P(null,1,5,$CLJS.Q,[f],null),new $CLJS.h(null,1,[$CLJS.r2,!0],null));return $CLJS.n(t)?$CLJS.n(l)?$CLJS.E.g($CLJS.P2(m),$CLJS.P2(f)):!0:t}),$CLJS.W5.g(d,e)))}function b(d,e,f){return c.v?c.v(d,e,f,null):c.call(null,d,e,f,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,
d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.$5=function(){function a(d,e,f){return $CLJS.fb(function(k,l){return $CLJS.R5.j(k,e,l)},d,$CLJS.Z5.j(d,e,f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();