var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var k7,m7,n7,gra,hra,ira,q7,r7,t7,u7,jra,v7,kra,lra;k7=function(a){var b=new $CLJS.h(null,3,[$CLJS.FE,$CLJS.p.h($CLJS.PE()),$CLJS.wB,$CLJS.wB.h(a),$CLJS.Ei,$CLJS.EA($CLJS.Ei,$CLJS.wB)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tB,b,$CLJS.EA($CLJS.xE,$CLJS.T)(a)],null)};$CLJS.l7=function(a){return $CLJS.B5.h(a)};m7=function(a){return $CLJS.R.j(a,$CLJS.Dj,$CLJS.S4)};
n7=function(a,b){return $CLJS.E.g($CLJS.sE.h(a),$CLJS.em(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JE,$CLJS.rE,$CLJS.IE],null)))};gra=function(a){return $CLJS.x1(a,new $CLJS.h(null,1,[$CLJS.JE,$CLJS.zh],null))};
hra=function(a,b){var c=$CLJS.Re(function(e){return $CLJS.AH(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SZ,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.OA($CLJS.Jy);if($CLJS.n($CLJS.NA("metabase.lib.field",c))){var d=$CLJS.oE("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([a])),$CLJS.Mh.l($CLJS.H([$CLJS.Rk.g($CLJS.SZ,b)]))]));d instanceof Error?$CLJS.MA("metabase.lib.field",c,$CLJS.Fw(),d):$CLJS.MA("metabase.lib.field",
c,$CLJS.Fw.l($CLJS.H([d])),null)}return null};
ira=function(a,b,c){if($CLJS.n(o7))return null;var d=o7;o7=!0;try{var e=$CLJS.j2(a,b),f=$CLJS.n(e)?$CLJS.MW(a,e):$CLJS.MW(a,b),k=function(){var m=$CLJS.p7.h(f);if($CLJS.n(m))return m;m=$CLJS.FA(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uV,$CLJS.JW],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.VV.h(f);if($CLJS.n(u))return u;u=$CLJS.lP.h(f);if($CLJS.n(u))return u;u=$CLJS.fQ.h(f);return $CLJS.n(u)?u:$CLJS.YE.h(f)}())?$CLJS.T0.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.OA($CLJS.Jy);if($CLJS.n($CLJS.NA("metabase.lib.field",
m))){var t=$CLJS.oE("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.MA("metabase.lib.field",m,$CLJS.Fw(),t):$CLJS.MA("metabase.lib.field",m,$CLJS.Fw.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?hra(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.K3($CLJS.Hk.l(l,$CLJS.Yi,$CLJS.H([$CLJS.eL,$CLJS.WW,$CLJS.QW])),null),$CLJS.T,function(){var m=$CLJS.SZ.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.UK,$CLJS.FZ):l:null}finally{o7=d}};
q7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.AP);var f=$CLJS.I(c,2,null);c=$CLJS.gl.l($CLJS.H([function(){var k=$CLJS.wB.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.wB,k],null):null}(),function(){var k=$CLJS.EA($CLJS.Ei,$CLJS.wB)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.Ei,k],null):null}(),function(){var k=$CLJS.BR.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.WW,k],null):null}(),function(){var k=$CLJS.uG.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.QW,k],null):null}(),$CLJS.Fd(f)?function(){var k=$CLJS.o2(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Dj,$CLJS.SK,$CLJS.T,f],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Dj,$CLJS.SK,$CLJS.T,f],null):function(){var k=ira(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Dj,$CLJS.SK,$CLJS.T,f],null)}()]));return $CLJS.n(d)?$CLJS.K3(c,d):c};r7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.QW);return $CLJS.n($CLJS.n(b)?$CLJS.Hd($CLJS.xH,b):b)?$CLJS.vj:$CLJS.EA($CLJS.Ei,$CLJS.wB)(a)};
$CLJS.s7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.FE),l=$CLJS.J.g(f,$CLJS.wB),m=$CLJS.J.g(f,$CLJS.BR),t=$CLJS.J.g(f,$CLJS.Ei);e=$CLJS.J.g(f,$CLJS.AP);var u=$CLJS.J.g(f,$CLJS.LN),v=$CLJS.J.g(f,$CLJS.uG),x=$CLJS.gl.l;k=new $CLJS.h(null,2,[$CLJS.Dj,$CLJS.SK,$CLJS.l1,k],null);f=$CLJS.tE.h(f);a=$CLJS.n(f)?f:$CLJS.i0.j(a,b,d);c=x.call($CLJS.gl,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.tE,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.Ei,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.wB,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.QW,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.WW,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.dL,u):m;return $CLJS.n(e)?$CLJS.K3(u,e):u};t7=function(a,b,c){return $CLJS.n($CLJS.Re(function(d){return $CLJS.E.g($CLJS.ri.h(d),c)},a))?$CLJS.Rk.g(function(d){var e=$CLJS.Hd(d,b)?$CLJS.Hk.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.ri.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
u7=function(a){var b=$CLJS.n($CLJS.T1.h(a))?null:function(){var d=$CLJS.UK.h(a),e=new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.WZ,null,$CLJS.b_,null,$CLJS.FZ,null],null),null);return e.h?e.h(d):e.call(null,d)}(),c=$CLJS.gl.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.FE,$CLJS.p.h($CLJS.PE()),$CLJS.wB,$CLJS.wB.h(a),$CLJS.Ei,r7(a)],null),function(){var d=$CLJS.M1(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.AP,d],null):null}(),function(){var d=$CLJS.QW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.uG,d],
null):null}(),function(){var d=$CLJS.UW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.UW,d],null):null}(),function(){var d=$CLJS.WW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.BR,d],null):null}(),function(){var d=$CLJS.dL.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.LN,d],null):null}()]));b=($CLJS.n(b)?$CLJS.EA($CLJS.SZ,$CLJS.T):$CLJS.EA($CLJS.Yi,$CLJS.T))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oG,c,b],null)};
jra=function(a,b){return $CLJS.lf(function(c){return $CLJS.E.g($CLJS.UK.h(c),$CLJS.MZ)},$CLJS.T0.v(a,b,$CLJS.MW(a,b),new $CLJS.h(null,3,[$CLJS.B0,!1,$CLJS.H0,!0,$CLJS.x0,!1],null)))};v7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.p7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);kra=new $CLJS.M(null,"earliest","earliest",-1928154382);lra=new $CLJS.M(null,"latest","latest",24323665);var ora;$CLJS.y1.m(null,$CLJS.oG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zh.h(b),$CLJS.x1(c,new $CLJS.h(null,2,[$CLJS.uG,$CLJS.zh,$CLJS.BR,gra],null)),a],null)});var o7=!1,w7=function w7(a,b){var d=$CLJS.E1(a,v7.h(b));a=$CLJS.n(v7.h(d))?w7.g?w7.g(a,d):w7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.T);return $CLJS.Sk.j(b,$CLJS.T,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.M0.m(null,$CLJS.SK,function(a,b,c){return r7(c)});$CLJS.M0.m(null,$CLJS.oG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.uG);$CLJS.I(c,2,null);c=q7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.QW,d):c;return $CLJS.N0.j(a,b,c)});$CLJS.O0.m(null,$CLJS.SK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.T);return $CLJS.R.j(a,$CLJS.T,b)});$CLJS.O0.m(null,$CLJS.oG,function(a,b,c){var d=q7(a,b,c);b=$CLJS.s7(a,b,d,c);return $CLJS.n(v7.h(b))?w7(a,b):b});
$CLJS.I0.m(null,$CLJS.SK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.tE),f=$CLJS.J.g(c,$CLJS.T),k=$CLJS.J.g(c,$CLJS.ri),l=$CLJS.J.g(c,$CLJS.WW),m=$CLJS.J.g(c,$CLJS.e_),t=$CLJS.J.g(c,$CLJS.dL),u=$CLJS.J.g(c,$CLJS.eL);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.K1.g($CLJS.J1,f):$CLJS.p.h(f);$CLJS.E.g(d,$CLJS.j0)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.E1(a,t),$CLJS.n(t)?a=(0,$CLJS.ma)($CLJS.IA($CLJS.tE.h($CLJS.l0.j(a,b,t)),$CLJS.YW,"")):(u=$CLJS.L1(a,u),a=$CLJS.i0.v(a,b,u,d))):a=null,m=$CLJS.n(a)?
a:$CLJS.n(m)?m:$CLJS.M1(c)):m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.eB($CLJS.IA($CLJS.gh(k),"-"," ")),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.y5(l,c),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):m});
$CLJS.I0.m(null,$CLJS.oG,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.BR);var k=$CLJS.J.g(f,$CLJS.AP),l=$CLJS.J.g(f,$CLJS.uG);f=$CLJS.J.g(f,$CLJS.LN);$CLJS.I(c,2,null);c=q7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.e_,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.ri,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.WW,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.dL,f):e;return $CLJS.n(e)?$CLJS.i0.v(a,b,e,d):$CLJS.QE("[Unknown Field]")});
$CLJS.J0.m(null,$CLJS.SK,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.T)});$CLJS.J0.m(null,$CLJS.oG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=q7(a,b,c);return $CLJS.n(c)?$CLJS.K0.j(a,b,c):"unknown_field"});
$CLJS.P0.m(null,$CLJS.SK,function(a,b,c){return $CLJS.gl.l($CLJS.H([function(){var d=$CLJS.tI($CLJS.P0,$CLJS.ci);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.E.g($CLJS.UK.h(c),$CLJS.WZ)?function(){var d=$CLJS.d_.h(c);return $CLJS.n(d)?(d=$CLJS.e0(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.VW,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.tE,$CLJS.T.h(d)],null)],null):null):null}():null]))});$CLJS.c1.m(null,$CLJS.oG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.uG.h(b)});
$CLJS.c1.m(null,$CLJS.SK,function(a){return $CLJS.QW.h(a)});
$CLJS.b1.m(null,$CLJS.oG,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Hd($CLJS.xH,b),e=$CLJS.uC($CLJS.UW,$CLJS.Ei,$CLJS.wB)(c);c=$CLJS.R.l(c,$CLJS.uG,b,$CLJS.H([$CLJS.Ei,d?$CLJS.vj:e,$CLJS.UW,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oG,c,a],null)}b=$CLJS.UW.h(c);c=$CLJS.n(b)?$CLJS.Hk.g($CLJS.R.j(c,$CLJS.Ei,b),$CLJS.UW):c;c=$CLJS.Hk.g(c,$CLJS.uG);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oG,c,a],null)});
$CLJS.b1.m(null,$CLJS.SK,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.QW,b,$CLJS.H([$CLJS.UW,$CLJS.uC($CLJS.UW,$CLJS.Ei,$CLJS.wB)(a)])):$CLJS.Hk.l(a,$CLJS.QW,$CLJS.H([$CLJS.UW]))});$CLJS.e1.m(null,$CLJS.oG,function(a,b,c){return $CLJS.f1.j(a,b,q7(a,b,c))});
$CLJS.e1.m(null,$CLJS.SK,function(a,b,c){if($CLJS.Nk.g($CLJS.UK.h(c),$CLJS.MZ)){a=$CLJS.EA($CLJS.Ei,$CLJS.wB)(c);b=null==c?null:$CLJS.ZN.h(c);if(null==b)var d=null;else try{var e=$CLJS.wD.h($CLJS.lj.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,kra),l=$CLJS.J.g(f,lra),m=$CLJS.zna.l($CLJS.H([$CLJS.$0.h(k),$CLJS.$0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Ur.g?$CLJS.Ur.g(1,m):$CLJS.Ur.call(null,1,m))?$CLJS.Ti:$CLJS.n($CLJS.Ur.g?$CLJS.Ur.g(31,m):$CLJS.Ur.call(null,31,m))?$CLJS.du:$CLJS.n($CLJS.Ur.g?
$CLJS.Ur.g(365,m):$CLJS.Ur.call(null,365,m))?$CLJS.nj:$CLJS.wj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.wC(a,$CLJS.wD)?$CLJS.Hna:$CLJS.wC(a,$CLJS.XC)?$CLJS.Gna:$CLJS.wC(a,$CLJS.WD)?$CLJS.Fna:$CLJS.Cf;d=$CLJS.n(d)?t7(e,$CLJS.ci,d):e;return $CLJS.n($CLJS.QW.h(c))?t7(d,$CLJS.q0,$CLJS.QW.h(c)):d}return $CLJS.Cf});
$CLJS.B5.m(null,$CLJS.oG,function(a){var b=null==a?null:$CLJS.ME(a);b=null==b?null:$CLJS.BR.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Dj,$CLJS.A5,$CLJS.H([$CLJS.z5,function(c,d){return q7(c,d,a)}]))});$CLJS.B5.m(null,$CLJS.SK,function(a){var b=null==a?null:$CLJS.WW.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Dj,$CLJS.A5,$CLJS.H([$CLJS.z5,$CLJS.Ue(a)]))});$CLJS.v5.m(null,$CLJS.oG,function(a,b){return $CLJS.NE(a,$CLJS.LE,$CLJS.H([$CLJS.BR,b]))});
$CLJS.v5.m(null,$CLJS.SK,function(a,b){return $CLJS.LE(a,$CLJS.WW,b)});$CLJS.C5.m(null,$CLJS.oG,function(a,b,c){return $CLJS.D5.j(a,b,q7(a,b,c))});
$CLJS.C5.m(null,$CLJS.SK,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.Ei);var d=$CLJS.J.g(b,$CLJS.ZN),e=$CLJS.J.g(b,$CLJS.Uj);if($CLJS.Nk.g($CLJS.UK.h(b),$CLJS.MZ)){var f=function(){var m=null==a?null:$CLJS.C1($CLJS.b0(a));m=null==m?null:$CLJS.kA.h(m);return null==m?null:$CLJS.Hd(m,$CLJS.BR)}(),k=$CLJS.FA(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,$CLJS.Oj],null)),l=$CLJS.l7(b);return function u(t){return new $CLJS.ne(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.Ad(v)){var x=$CLJS.lc(v),
z=$CLJS.D(x),C=$CLJS.qe(z);return function(){for(var K=0;;)if(K<z){var S=$CLJS.kd(x,K),V=C,Z=S;S=n7(S,l)?$CLJS.R.j(Z,$CLJS.q0,!0):Z;V.add(S);K+=1}else return!0}()?$CLJS.te($CLJS.ve(C),u($CLJS.mc(v))):$CLJS.te($CLJS.ve(C),null)}var G=$CLJS.A(v);return $CLJS.ee(function(){var K=G;return n7(G,l)?$CLJS.R.j(K,$CLJS.q0,!0):K}(),u($CLJS.Lc(v)))}return null}},null,null)}($CLJS.Va(function(){if($CLJS.n(f)){var t=$CLJS.Zn.h(k);return $CLJS.n(t)?$CLJS.ik.h(k):t}return f}())?null:$CLJS.wC(e,$CLJS.xj)?$CLJS.Rk.g(m7,
new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.x5(),new $CLJS.h(null,2,[$CLJS.tE,$CLJS.QE("Bin every 0.1 degrees"),$CLJS.sE,new $CLJS.h(null,2,[$CLJS.JE,$CLJS.IE,$CLJS.IE,.1],null)],null),new $CLJS.h(null,2,[$CLJS.tE,$CLJS.QE("Bin every 1 degree"),$CLJS.sE,new $CLJS.h(null,2,[$CLJS.JE,$CLJS.IE,$CLJS.IE,1],null)],null),new $CLJS.h(null,2,[$CLJS.tE,$CLJS.QE("Bin every 10 degrees"),$CLJS.sE,new $CLJS.h(null,2,[$CLJS.JE,$CLJS.IE,$CLJS.IE,10],null)],null),new $CLJS.h(null,2,[$CLJS.tE,$CLJS.QE("Bin every 20 degrees"),
$CLJS.sE,new $CLJS.h(null,2,[$CLJS.JE,$CLJS.IE,$CLJS.IE,20],null)],null)],null)):$CLJS.wC(c,$CLJS.Oj)&&!$CLJS.wC(e,$CLJS.mi)?$CLJS.Rk.g(m7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.x5(),new $CLJS.h(null,2,[$CLJS.tE,$CLJS.QE("10 bins"),$CLJS.sE,new $CLJS.h(null,2,[$CLJS.JE,$CLJS.rE,$CLJS.rE,10],null)],null),new $CLJS.h(null,2,[$CLJS.tE,$CLJS.QE("50 bins"),$CLJS.sE,new $CLJS.h(null,2,[$CLJS.JE,$CLJS.rE,$CLJS.rE,50],null)],null),new $CLJS.h(null,2,[$CLJS.tE,$CLJS.QE("100 bins"),$CLJS.sE,new $CLJS.h(null,2,
[$CLJS.JE,$CLJS.rE,$CLJS.rE,100],null)],null)],null)):null)}return $CLJS.Cf});$CLJS.vW.m(null,$CLJS.oG,function(a){return a});
$CLJS.vW.m(null,$CLJS.SK,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.UK);switch(b instanceof $CLJS.M?b.T:null){case "source/aggregations":return b=new $CLJS.h(null,2,[$CLJS.FE,$CLJS.p.h($CLJS.PE()),$CLJS.Ei,$CLJS.EA($CLJS.Ei,$CLJS.wB)(a)],null),a=$CLJS.l1.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dG,b,a],null);case "source/expressions":return k7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.xE.h(a))?k7(a):u7(a);default:return u7(a)}});
$CLJS.mra=function(){function a(e,f,k){k=$CLJS.Fe($CLJS.Rk.g($CLJS.xW,k));var l=jra(e,f),m=$CLJS.eg.j($CLJS.bh,$CLJS.Xl(function(t){return $CLJS.u2.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.Cf);m=$CLJS.Qk.g(m,l);k=$CLJS.n(k)?$CLJS.eg.j(k,$CLJS.hf.h($CLJS.xW),m):null;return $CLJS.XW.l(e,f,$CLJS.LE,$CLJS.H([$CLJS.YE,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.x7=function(){function a(d,e){return $CLJS.YE.h($CLJS.MW(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.nra=function(){function a(d,e){var f=$CLJS.T0.v(d,e,$CLJS.MW(d,e),new $CLJS.h(null,3,[$CLJS.B0,!1,$CLJS.H0,!1,$CLJS.x0,!1],null)),k=$CLJS.x7.g(d,e);return $CLJS.td(k)?$CLJS.Rk.g(function(l){return $CLJS.R.j(l,$CLJS.UZ,!0)},f):$CLJS.I3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
ora=function(){function a(d,e,f){var k=$CLJS.MW(d,e),l=$CLJS.E.g($CLJS.iC(f),$CLJS.dG)?$CLJS.R0:$CLJS.T0;k=l.j?l.j(d,e,k):l.call(null,d,e,k);return $CLJS.u2.v(d,e,f,k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.pra=function(){function a(d,e,f){f=$CLJS.BZ.j(d,e,f);return ora.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();